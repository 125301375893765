import React, { useState } from 'react';
import { graphql } from 'gatsby'

import Head from '../components/Head';
import NavBar from '../components/NavBar';
import CtaContact from '../components/CtaContact';
import Img from 'gatsby-image';

import '../scss/project.scss';

function Project (props) {
    const [navOpen, setNavOpen] = useState(false);

    const navCallback = (state) => {
        setNavOpen(state);
    }

    let data = props.data.contentfulProject;

    if (!data.youtubeLink)
        return (<h1>Please add youtube link to this project</h1>)

    let youtubeVidId = data.youtubeLink.split('=')[1];

    if (!data.previewPhoto)
        return (<h1>Please add a preview photo to this project</h1>)

    // const highlightPhotoBg = {
    //     backgroundImage: `url(https:${data.previewPhoto.file.url})`,
    //     // backgroundAttachment: 'fixed'
    //     backgroundAttachment: (navOpen ? 'local' : 'fixed')
    // }
    let block_count = data.photos.length % 3;
    console.log(data)
    return (
        <div className='project'>
            <Head url={`https://northboundfilms.com/${props.pageContext.slug}`}></Head>

            <div className='nav-container'>
                <NavBar secondary={true} parentCallback={navCallback}></NavBar>
                
                <div className={(navOpen ? 'disabled-content' : null)} />
                <div className={`mobile-margin-top transition ${(navOpen ? 'shrink' : null)}`}>
                    {/* <div className='container'>
                        <div className='highlightPhotoBg' style={highlightPhotoBg}>
                            <div className='overlay'/>
                        </div>
                    </div> */}
                    <div className='container'>
                        <div className='content'>
                            {/* <div className='clientLogos'>
                                <img src={`https:${data.clientLogos[0].file.url}`}></img>
                            </div> */}
                            <h1 className='header'>{data.title}</h1>
                            <h2 className='subheader'>{data.client}</h2>
                            {
                                data.scope && (
                                    // <p className='descv'></p>
                                    <p className='description1 paragraph'>{data.scope.scope}</p>
                                )
                            }
                            {
                                data.challenges &&
                                <p className='description1 paragraph'>{data.challenges.challenges}</p>
                            }
                            {
                                data.achievements &&
                                <p className='description1 paragraph'>{data.achievements.achievements}</p>
                            }
                            <div className='video-responsive'>
                                <iframe frameBorder='0' allowFullScreen
                                    src={`https://youtube.com/embed/${youtubeVidId}`}/>
                            </div>
                            <div className='flex-container photos1'>
                                <Img className='flex-item' fluid={data.photos[0].fluid}></Img>
                                <Img className='flex-item' fluid={data.photos[1].fluid}></Img>
                            </div>
                            {
                                data.description2 &&
                                    <p className='description2 paragraph'>
                                        {data.description2.description2}
                                    </p>                            
                            }
                            {data.photos[2] &&
                                <div className='photo2'>
                                    <Img className='flex-item' fluid={data.photos[2].fluid}></Img>
                                </div>
                            }
                            { data.photos[3] &&
                                <div className='photos3 flex-container' src={data.photos[3].file.url}>
                                    {
                                        data.photos.map((photo, i) => {
                                            if (i < 2) return false
                                            let center;
                                            if (i === (data.photos.length - 1) && block_count === 0)
                                                center = true;
                                            return <Img className={`flex-item ${center ? 'center-item' : null}`} fluid={photo.fluid} key={i}></Img>
                                        })
                                    }
                                </div>
                            }
                            <CtaContact />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Project;

export const query = graphql`
    fragment ProjectFragment on ContentfulProject {
        title
        client
        clientLogos {
            fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp
            }
            file {
                url
                fileName
            }
        }
        youtubeLink
        photos {
            fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
            }
            file {
                url
                fileName
            }
            description
        }
        previewPhoto {
            fluid(maxWidth: 1600, quality: 90){
                ...GatsbyContentfulFluid_withWebp
            }
            file {
                url
                fileName
            }
        }
        dateFinished
        description {
            description
        }
        description2 {
            description2
        }
        scope {
            scope
        }
        challenges {
            challenges
        }
        achievements {
            achievements
        }
    }
    query($title: String!) {
        contentfulProject(title: { eq: $title }) {
            ...ProjectFragment
        }
    }
`