import React, { useState } from 'react';
import { Link } from 'gatsby'

import { InlineIcon } from '@iconify/react';
import arrowRight from '@iconify/icons-cil/arrow-right';

import '../scss/cta-contact.scss';

function Project (props) {
    return (
        <div className='cta-contact'>
            <h1 className='subheader'>Want to work with us?</h1>
            <Link to='/contact' className='button1'>
                <h2>Contact</h2>
                <InlineIcon icon={arrowRight} className='arrow-right'/>
            </Link>
        </div>
    )
}

export default Project;